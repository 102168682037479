<template>
    <div class="client-box">
         <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>库存管理</el-breadcrumb-item>
            <el-breadcrumb-item>物品列表</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 顶部栏 -->
        <div class="header-bar">
            <el-form :inline="true" :model="form" class="demo-form-inline">
                <el-form-item v-if="addRule">
                    <el-button @click="add" type="primary" size="small" icon="el-icon-plus" round>添加</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button @click="showImportGoods=true" 
                    type="warning" size="small" icon="el-icon-upload2" round>导入</el-button>
                </el-form-item>
                <el-form-item v-if="addRule">
                    <el-button round plain @click="download" type="success" size="small" icon="el-icon-download">导出</el-button>
                </el-form-item>
                <el-form-item >
                    <el-select v-model="form.wid" clearable placeholder="选择仓库" @change="changeWarehouse">
                        <el-option
                        v-for="item in warehouseList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物品">
                     <el-input v-model="form.key"  placeholder="输入物品或编号"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="margin" @click="search" icon="el-icon-search" size="small">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="pro-con">
            <!-- table -->
            <el-table
            border
            v-loading="loading"
            :data="list"
            @selection-change="handleSelectionChange"
            style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="num"
                    label="编号">
                </el-table-column>
                <el-table-column
                    prop="w_logo"
                    label="图片">
                    <template slot-scope="scope">
                        <el-image
                            v-if="scope.row.w_logo!=''"
                            style="width: 100px; height: 100px"
                            :src="scope.row.w_logo"
                            fit="fill"></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="cn_name"
                    label="名称">
                    <template slot-scope="scope">
                        {{scope.row.cn_name}} 
                        <div class="enName">{{scope.row.en_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="unit"
                    label="单位">
                </el-table-column>
                <el-table-column
                    prop="price"
                    label="单价">
                    <template slot-scope="scope">
                        {{scope.row.price}}元
                    </template>
                </el-table-column>
                <el-table-column
                    prop="classification"
                    label="类别">
                </el-table-column>
                <el-table-column
                    prop="composition"
                    label="成分">
                </el-table-column>
                <el-table-column
                    prop="ratio"
                    label="物料配比">
                </el-table-column>
                <el-table-column
                    label="上次盘点库存">
                    <template slot-scope="scope">
                         <el-tooltip class="item" effect="dark" :content="scope.row.time" placement="bottom-end">
                             <div>{{scope.row.number}}{{scope.row.c_unit}}</div>
                         </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column
                    label="库存结存">
                    <template slot-scope="scope">
                        <div class="surp" v-if="scope.row.surplus" @click="clickStock(scope.row)">
                            {{scope.row.surplus+scope.row.surplusUnit}}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- content-end -->
        <!-- 分页 -->
        <div class="block">
            <el-pagination
                 @current-change="changePage"
                layout="prev, pager, next"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
        <!-- 添加物品 -->
        <el-dialog title="添加物品" 
        :before-close="closeAdGoods"
        :visible.sync="drawer">
            <AddGoods ref="addGoods" :updateId="updateId" @addSuc="addSuc" @editTy="ToEditTy" :unitList="unitList" :typeList="typeList"  :usageList="usageList"/>
        </el-dialog>
        <!-- 添加物品类别 -->
        <el-dialog title="物品类别" 
        :visible.sync="typeDrawer">
            <Type :list="typeList" @refresh="refreshType"/>
        </el-dialog>
        <!-- 添加物品单位 -->
        <el-dialog title="物品单位" 
        :visible.sync="unitDrawer">
            <Unit :list="unitList" @refresh="refreshUnit"/>
        </el-dialog>
        <!-- 添加物品使用方式 -->
        <el-dialog title="使用方式" 
        :visible.sync="usageDrawer">
            <Usage :list="usageList" @refresh="refreshUsage"/>
        </el-dialog>
        <!-- 添加物品使用方式 -->
        <el-dialog title="导入物料" 
        :visible.sync="showImportGoods">
            <ImportGoods @close="showImportGoods=false"/>
        </el-dialog>
        <!-- 库存数据结算流程 -->
        <el-dialog title="物料出入库记录" 
        :visible.sync="showFlow">
            <stockCheckFlow @close="showFlow=false" :data="currentStock"/>
        </el-dialog>
    </div>
</template>
<script>
import stockCheckFlow from '@/components/stockCheckFlow'
import {exportExcel} from '@/utils/excel.js'
import Unit from '@/components/AddGoodsUnit'
import Type from '@/components/AddGoodsType'
import Usage from '@/components/AddGoodsUsage'
import AddGoods from '@/components/AddGoods'
import ImportGoods from '@/components/ImportGoods'
import {checkRules,ConvertUnits} from '@/utils/util.js'
export default {
    components:{
        stockCheckFlow,
        AddGoods,
        Unit,
        Type,
        Usage,
        ImportGoods
    },
    data(){
        return{
            currentStock:{},
            showFlow:false,//显示库存流程
            warehouseList:[],
            modifyRule:checkRules(12),//添加权限
            showImportGoods:false,
            updateId:"",//要更新的wid
            unitDrawer:false,//管理物品单位
            unitList:[],
            typeDrawer:false,//管理物品类别
            typeList:[],
            usageDrawer:false,//管理使用方式
            usageList:[],//物品使用方式类别
            drawer:false,
            editRule:true,
            loading:false,
            list:[],
            addRule:checkRules(12),//添加权限
            form:{
                key:"",
                wid:""
            },
            page:1,
            pageSize:5,
            totalPage: 1,
            total:0,
            showDel:false,//显示删除
            selectList:[],//删除的列表
            downloadList:[] //导出列表 
        }
    }
    ,created(){
        this.form.wid = localStorage.getItem("currentWid")||""
        this.searchWarehouse().then(()=>{
            if(!this.form.wid){
                console.log('000',this.form.wid)
                //没有默认仓库，则选择最后一个
                this.form.wid = this.warehouseList[0]?this.warehouseList[0].wid:''
            }
            this.getGoods()
        })
        this.getUTU()
    }
    ,methods:{
        clickStock(row){
            this.currentStock = row 
            this.showFlow = true
        },
        //删除多个
        deleteList(){
            this.$confirm('此操作将永久删除选择的数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.confirmDel()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //确定删除
        confirmDel(){
            let ids='',l=this.selectList.length 
            if(l<=0){
                return
            }
            this.selectList.forEach((item,index)=>{
                if(index!=l-1){
                    ids += item.wid+","
                }else{
                    ids += item.wid
                }
            })
            let params = {
                method:"delGoodsList",
                ids
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //todo
                console.log(res)
                this.loading = false 
                this.$message({
                        message: res.msg||'操作成功！',
                        type: 'success'
                    });
                this.search()
                this.selectList=[]
            if(res.data){
                this.totalPage = res.data.totalPage 
                this.list = res.data.list
            }
            //console.log(list)
            }).catch(err=>{
            //err
            console.log(err.data)
                this.loading = false
                this.$message({
                        message: err.msg?err.msg:this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
            }) 
        },
        //选择仓库
        changeWarehouse(e){
            console.log(e)
            this.form.wid = e 
            localStorage.setItem("currentWid",e)
        },
        //表单选择
        handleSelectionChange(e){
            console.log(e)
            this.selectList=e
            e.length<=0?this.showDel=false:this.showDel=true
        },
        //导出物品
        download(){
            if(this.loading){
                return
            }
            let form = this.form
            form.method = "getGoodsStock"
            form.page = 1
            form.pageSize = 10000
            this.loading = true
            this.$ajax.post(this.API.api,form).then(({data})=>{
                this.loading = false 
                console.log(data)
                this.downloadList = data.list
                this.downloadList.forEach(item=>{
                        if(item.en_list||item.ex_list){
                            //
                          let itemSurplus =  ConvertUnits(item.en_list,item.ex_list,item.number,item.c_unit,item.specification)
                          item.surplus = itemSurplus.surplus
                          item.surplusUnit = itemSurplus.unit
                        }
                })
                this.export()
            }).catch(err=>{
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        export(){
            let columnTitle=['编号','中文','英文','单价','单位','单位规格转换','结存']
            let columnData = []
            //console.log(this.list)
            this.downloadList.forEach(it=>{
                console.log(it.surplus+it.surplusUnit)
                    columnData.push([it.num,it.cn_name,it.en_name,it.price,it.unit,it.specification,it.surplus+it.surplusUnit])
            })
            exportExcel(columnTitle, columnData, "物料数据导出明细");
        },
        closeAdGoods(cb){
            this.$refs['addGoods'].close(cb)
            return false
        }, //搜索仓库
        async searchWarehouse(key){
             !key?key='':''
            let params = {
                method:"getWarehouse",
                key,
                page:1,
                pageSize:5
            }
            this.loading = true
            return new Promise((resolve,reject)=>{
                this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                //console.log(data)
                if(data){
                    data.list.forEach(item=>{
                        item.label = item.name 
                        item.value = item.num
                    })
                    this.warehouseList = data.list
                    resolve(data.list)
                }
                }).catch(err=>{
                    this.loading = false 
                    reject(err)
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
            })
           
            
        },
        //添加成功刷新
        addSuc(){
            this.drawer = false
            this.getGoods()
        },
        //更新物品单位
        refreshUnit(data){
            this.unitList = data.list
        },
        //更新物品类别
        refreshType(data){
            this.typeList = data.list
        },
        //更新使用方式
        refreshUsage(data){
            this.usageList = data.list
        },
        //获取物品使用方式,类别，和单位
        getUTU(){
            let params = {
                method:"getUTU"
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                this.loading = false 
                //console.log(data)
                if(data){
                    data.typeList.forEach(item=>{
                        item.value = item.name
                    })
                    this.typeList = data.typeList 
                    data.unitList.forEach(item=>{
                        item.value = item.name
                    })
                    this.unitList = data.unitList 
                    data.usageList.forEach(item=>{
                        item.value = item.name
                    })
                    this.usageList = data.usageList
                    console.log(this.typeList,this.unitList,this.usageList)
                }
            }).catch(err=>{
                console.log(err.data)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        changePage(page){
            this.page = page
            this.getGoods()
        },
        //删除
        handleDelete(wid){
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                        let params = {
                            method:"delGoods",
                            wid
                        }
                        this.loading = true
                    this.$ajax.post(this.API.api,params).then(()=>{
                        this.loading = false 
                            this.$message({
                                message: this.CONST.DEL_SUC,
                                type: 'success'
                            });
                            //刷新
                            this.getGoods()
                    }).catch(err=>{
                        this.loading = false 
                            this.$message({
                                message: err.msg?err.msg:err,
                                type: 'warning'
                            });
                    })  
                }).catch(err=>{
                    this.$message({
                        type: 'info',
                        message: err.msg||this.CONST.DEL_CANCEL
                    });      
                })
        },
        //编辑
        handleEdit(wid){
            console.log(wid)
            this.updateId = wid.toString()
            this.drawer = true
        },
        //添加物品
        add(){
            this.drawer = true
        },
        //搜索物品
        search(){
            this.page = 1
            this.getGoods()
        },
        //编辑类别和单位
        ToEditTy(num){
            console.log(num)
            switch(num){
                case 0:
                    //使用方式
                    this.unitDrawer = true
                    break;
                case 1:
                    //编辑类别
                    this.typeDrawer = true
                    break;
                case 2:
                    //编辑单位
                    this.usageDrawer = true
                    break;
            }
        },
        //获取物品列表
        getGoods(){
            let form = this.form
            form.method = "getGoodsStock"
            form.page = this.page 
            form.pageSize = this.pageSize
            this.loading = true
            //console.log(form)
            this.$ajax.post(this.API.api,form).then(({data})=>{
                this.loading = false 
                //console.log(data.list,msg)
                if(data){
                    this.list = data.list
                    this.list.forEach(item=>{
                        if(item.en_list||item.ex_list){
                            //
                          let itemSurplus =  ConvertUnits(item.en_list,item.ex_list,item.number,item.c_unit,item.specification)
                          item.surplus = itemSurplus.surplus
                          item.surplusUnit = itemSurplus.unit
                        }
                    })
                    this.totalPage = data.totalPage
                    this.total = parseInt(data.total)
                }
            }).catch(err=>{
                console.log(err.data||err)
                this.loading = false 
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">

</style>