<template>
    <div class="flow-box">
        <div class="btn-box">{{data.cn_name}}</div>
        <el-table
            border
            v-loading="loading"
            :data="list"
            style="width: 100%">
            <el-table-column
                prop="time"
                label="日期">
            </el-table-column>
            <el-table-column
                prop="desc"
                label="类型">
            </el-table-column>
            <el-table-column
                label="数量">
                <template slot-scope="scope">
                    <div class="d-number">
                        {{scope.row.number+" "+scope.row.unit}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="to"
                label="往来单位">
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import {formatTime} from '@/utils/util'
export default {
    name:"stockCheckFlow",
    props:{
        data:{
            type:Object,
        }
    },
    watch:{
        data:{
            immediate: true,
            handler(data){
                this.initData(data)
            },
            deep:true
        }
    },
    data(){
        return{
            list:[],
            loading:false
        }
    },
    methods:{
        initData(data){
            console.log(data)
            let list=[]
            list.push({
                time:data.time,
                desc:"最近盘点库存",
                number:data.number,
                unit:data.c_unit
            })
            //获取出入库数据
            if(data.en_list){
                data.en_list.forEach(item=>{
                    list.push({
                        time:item.date,
                        desc:"入库",
                        number:"+"+item.specification_number,
                        unit:item.unit,
                        to:item.related_party
                    })
                })
            }
            if(data.ex_list){
                data.ex_list.forEach(item=>{
                    list.push({
                        time:item.date,
                        desc:"出库",
                        number:"-"+item.specification_number,
                        unit:item.unit,
                        to:item.related_party
                    })
                })
            }
            //结存
            list.push({
                time:formatTime(new Date),
                desc:"结存",
                number:data.surplus,
                unit:data.surplusUnit
            })
            list.sort(function(a, b) {
                return b.time> a.time? -1 : 1
            })

            this.list=list
        }
    }
}
</script>